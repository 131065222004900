import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const CreateBacklog = Loadable(lazy(() => import('pages/create_backlog')));
const CreateTask = Loadable(lazy(() => import('pages/create_task')));
const ListBacklog = Loadable(lazy(() => import('pages/list_backlog')));
const Backlog = Loadable(lazy(() => import('pages/backlog')));

// const DashboardPage = Loadable(lazy(() => import('pages/dashboard/default')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        // {
        //   path: "dashboard",
        //   children: [
        //     {
        //       path: "default",
        //       element: <DashboardDefault />,
        //     },
        //     {
        //       path: "analytics",
        //       element: <DashboardAnalytics />,
        //     },
        //   ],
        // },
        {
          path: '/',
          element: <CreateBacklog />,
        },
        // {
        //   path: "dashboard",
        //   element: <DashboardPage />,
        //   rights: "ngtRequest",
        // },
        {
          path: 'overview',
          element: <CreateBacklog />,
          rights: 'ngtRequest',
        },
        {
          path: 'create-backlog',
          element: <CreateBacklog />,
          rights: 'ngtRequest',
        },
        {
          path: 'create-task',
          element: <CreateTask />,
          rights: 'ngtRequest',
        },
        {
          path: 'list-backlogs',
          element: <ListBacklog />,
          rights: 'ngtTemplate',
        },
        {
          path: 'list-backlogs/:id',
          element: <Backlog />,
          rights: 'ngtTemplate',
        },
        {
          path: '*',
          element: <CreateBacklog />,
          rights: 'ngtRequest',
        },
      ],
    },
    // {
    //   path: "/maintenance",
    //   element: <CommonLayout />,
    //   children: [
    //     {
    //       path: "404",
    //       element: <MaintenanceError />,
    //     },
    //     {
    //       path: "500",
    //       element: <MaintenanceError500 />,
    //     },
    //     {
    //       path: "under-construction",
    //       element: <MaintenanceUnderConstruction />,
    //     },
    //     {
    //       path: "coming-soon",
    //       element: <MaintenanceComingSoon />,
    //     },
    //   ],
    // },
    // {
    //   path: "/auth",
    //   element: <CommonLayout />,
    //   children: [
    //     {
    //       path: "login",
    //       element: <AuthLogin />,
    //     },
    //     {
    //       path: "register",
    //       element: <AuthRegister />,
    //     },
    //     {
    //       path: "forgot-password",
    //       element: <AuthForgotPassword />,
    //     },
    //     {
    //       path: "reset-password",
    //       element: <AuthResetPassword />,
    //     },
    //     {
    //       path: "check-mail",
    //       element: <AuthCheckMail />,
    //     },
    //     {
    //       path: "code-verification",
    //       element: <AuthCodeVerification />,
    //     },
    //   ],
    // },
  ],
};

export default MainRoutes;
