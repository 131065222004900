import { useRef, useState } from 'react';
import { useNavigate } from 'react-router';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Grid,
  ListItemIcon,
  Paper,
  Popper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

// project import
// import ProfileTab from './ProfileTab';
// import SettingTab from './SettingTab';
import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';
import IconButton from 'components/@extended/IconButton';
import useAuth from 'hooks/useAuth';
import { FiUser } from 'react-icons/fi';

// assets
import { LogoutOutlined } from '@ant-design/icons';

// types
import { ThemeMode } from 'types/config';
import { decrypt, encrypt, getCookie } from 'utils/commonFunctions';
// import Cookies from 'js-cookie';

// types
// interface TabPanelProps {
//   children?: ReactNode;
//   dir?: string;
//   index: number;
//   value: number;
// }

// tab panel wrapper
// function TabPanel(props: TabPanelProps) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div role="tabpanel" hidden={value !== index} id={`profile-tabpanel-${index}`} aria-labelledby={`profile-tab-${index}`} {...other}>
//       {value === index && children}
//     </div>
//   );
// }

// function a11yProps(index: number) {
//   return {
//     id: `profile-tab-${index}`,
//     'aria-controls': `profile-tabpanel-${index}`
//   };
// }

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  // const { logout, user } = useAuth();
  const { logout } = useAuth();
  const handleLogout = async () => {
    try {
      await logout();
      clearCookieStorage();
      navigate(`/`, {
        state: {
          from: '',
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  // console.log("process.env.REACT_APP_USERINFO",process.env.REACT_APP_USERINFO);

  const userCookie: string = getCookie(encrypt(process.env.REACT_APP_USERINFO || ''));
  console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!userCookie",userCookie);
  const userData = userCookie ? JSON.parse(decrypt(userCookie)) : '';
  console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!userData",userData);

  function clearCookieStorage(hotReload = false) {
    localStorage.clear();
    (() => {
      let cookies = document.cookie.split('; ');
      for (let c = 0; c < cookies.length; c++) {
        let d = window.location.hostname.split('.');
        while (d.length > 0) {
          let cookieBase =
            encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) +
            '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' +
            d.join('.') +
            ' ;path=';
          let p = window.location.pathname.split('/');
          document.cookie = cookieBase + '/';
          while (p.length > 0) {
            document.cookie = cookieBase + p.join('/');
            p.pop();
          }
          d.shift();
        }
      }
      const host = window.location.host;
      const parts = host.split('.');
      console.log(parts);
      parts.shift();
      console.log(parts);

      let domain = parts.join('.');
      console.log(domain);
      window.location.assign(
        `${process.env.REACT_APP_ACCOUNT_LOGIN}/auth/logout/${encodeURIComponent(
          encrypt(window.location.href)
        )}` || '/'
      );
    })();
    if (hotReload) window.location.reload();
  }

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  // const [value, setValue] = useState(0);

  // const handleChange = (event: SyntheticEvent, newValue: number) => {
  //   setValue(newValue);
  // };

  const iconBackColorOpen = theme.palette.mode === ThemeMode.DARK ? 'grey.200' : 'grey.300';

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: open ? iconBackColorOpen : 'transparent',
          borderRadius: 1,
          '&:hover': {
            bgcolor:
              theme.palette.mode === ThemeMode.DARK ? 'secondary.light' : 'secondary.lighter',
          },
          '&:focus-visible': {
            outline: `2px solid ${theme.palette.secondary.dark}`,
            outlineOffset: 2,
          },
        }}
        aria-label='open profile'
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup='true'
        onClick={handleToggle}
      >
        <Stack direction='row' spacing={2} alignItems='center' sx={{ p: 0.5 }}>
          {/* <Avatar alt="profile user" src={avatar1} size="xs" /> */}
          <ListItemIcon>
            <FiUser size={'1.3rem'} />
          </ListItemIcon>
          <Typography
            variant='subtitle1'
            style={{
              fontSize: '0.875rem',
              fontWeight: 600,
              lineHeight: 1.57,
              fontFamily: '"Public Sans", sans-serif',
              textTransform: 'capitalize',
            }}
          >
            {userData?.userName}
          </Typography>
        </Stack>
      </ButtonBase>
      <Popper
        placement='bottom-end'
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type='grow' position='top-right' in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: 290,
                minWidth: 240,
                maxWidth: 290,
                [theme.breakpoints.down('md')]: {
                  maxWidth: 250,
                },
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard elevation={0} border={false} content={false}>
                  <CardContent sx={{ px: 2.5, pt: 3 }}>
                    <Grid container justifyContent='space-between' alignItems='center'>
                      <Grid item>
                        <Stack direction='row' spacing={1.25} alignItems='center'>
                          {/* <Avatar alt="profile user" src={avatar1} sx={{ width: 32, height: 32 }} /> */}
                          <ListItemIcon>
                            <FiUser size={'1.5rem'} />
                          </ListItemIcon>
                          <Stack>
                            <Typography variant='h6'>{userData?.userName}</Typography>
                            <Typography variant='body2' color='textSecondary'>
                              {userData?.companyName}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Grid>
                      <Grid item>
                        <Tooltip title='Logout'>
                          <IconButton
                            size='large'
                            sx={{ color: 'text.primary' }}
                            onClick={handleLogout}
                          >
                            <LogoutOutlined />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </CardContent>

                  {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs variant="fullWidth" value={value} onChange={handleChange} aria-label="profile tabs">
                      <Tab
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          textTransform: 'capitalize'
                        }}
                        icon={<UserOutlined style={{ marginBottom: 0, marginRight: '10px' }} />}
                        label="Profile"
                        {...a11yProps(0)}
                      />
                      <Tab
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          textTransform: 'capitalize'
                        }}
                        icon={<SettingOutlined style={{ marginBottom: 0, marginRight: '10px' }} />}
                        label="Setting"
                        {...a11yProps(1)}
                      />
                    </Tabs>
                  </Box>
                  <TabPanel value={value} index={0} dir={theme.direction}>
                    <ProfileTab handleLogout={handleLogout} />
                  </TabPanel>
                  <TabPanel value={value} index={1} dir={theme.direction}>
                    <SettingTab />
                  </TabPanel> */}
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Profile;
