import { useEffect, useState } from 'react';

// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';

import Loader from 'components/Loader';
import Locales from 'components/Locales';
import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/@extended/Snackbar';
import Notistack from 'components/third-party/Notistack';

import { RootState, dispatch } from 'store';
import { fetchMenu } from 'store/reducers/menu';

// auth-provider
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import { setTranslation } from 'store/reducers/translation';
import { useSelector } from 'react-redux';
import axiosServices from 'utils/axios';
import Cookies from "js-cookie";
import { encrypt } from "utils/commonFunctions";
import { useLocation } from 'react-router';
// import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const translationData = useSelector((state: RootState) => state.translation.data);
  const location = useLocation();

  useEffect(() => {
    // fetch dashboard menu from API
    let cookieExist = Cookies.get(process.env.REACT_APP_EXPIRE_COOKIE || "");
    console.log('app called');
    const host = window.location.host;
    const parts = host.split('.');
    console.log(parts);
    parts.shift();
    console.log(parts);

    let domain = parts.join('.');
    console.log(domain);
    if (!cookieExist) {
      const externalUrl =
        `${
          process.env.REACT_APP_ACCOUNT_LOGIN
        }/auth/logout/${encodeURIComponent(encrypt(window.location.href))}` ||
        "/";
      window.location.assign(externalUrl);
    }
    if (location.pathname.includes('/login')) {
      window.location.assign('/');
    }
    dispatch(fetchMenu()).then(() => {
      setLoading(false);
    });
  }, []);

  const getTranslationData = () => {
    axiosServices
      .get('/translations')
      .then((response) => {
        dispatch(setTranslation(response.data.data));
      })
      .catch((error) => {
        console.error('Error fetching API data:', error);
      });
  };

  useEffect(() => {
    if (!Object.keys(translationData).length) {
      getTranslationData();
    }
  }, []);

  if (loading) return <Loader />;

  return (
    <ThemeCustomization>
      <RTLLayout>
        <Locales>
          <ScrollTop>
            <AuthProvider>
              <>
                <Notistack>
                  <Routes />
                  <Snackbar />
                </Notistack>
              </>
            </AuthProvider>
          </ScrollTop>
        </Locales>
      </RTLLayout>
    </ThemeCustomization>
  );
};

export default App;
