import axios, { AxiosInstance } from "axios";
// const host = window.location.host
//     const parts = host.split(".");
//     console.log(parts);
//     parts.shift();  
//     console.log(parts);
    
//     let domain = parts.join('.')
//     console.log(domain);
//     console.log(process.env.REACT_APP_API_URL);
const axiosServices: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_IP || "http://localhost:3010/",});

// ==============================|| AXIOS - FOR MOCK SERVICES ||============================== //

axiosServices.interceptors.request.use(
  (request) => {
      request.withCredentials = true;
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosServices.interceptors.response.use(
  (response) => response,
  (error) => {
    // if (
    //   error.response.status === 401 &&
    //   !window.location.href.includes("/login")
    // ) {
    //   window.location.pathname = "/login";
    // }
    return Promise.reject(
      (error.response && error.response.data) || "Wrong Services"
    );
  }
);

export default axiosServices;
