// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  BuildOutlined,
  CalendarOutlined,
  CustomerServiceOutlined,
  FileTextOutlined,
  MessageOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  AppstoreAddOutlined,
  DashboardOutlined,
  FileOutlined,
  AreaChartOutlined,
  ProfileOutlined,
  AccountBookOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';

// type
import { NavItemType } from 'types/menu';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AddIcon from '@mui/icons-material/Add';
import AddTaskIcon from '@mui/icons-material/AddTask';

// icons
const icons = {
  BuildOutlined,
  CalendarOutlined,
  CustomerServiceOutlined,
  MessageOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  AppstoreAddOutlined,
  FileTextOutlined,
  DashboardOutlined,
  FileOutlined,
  AreaChartOutlined,
  ProfileOutlined,
  AccountBookOutlined,
  PlusCircleOutlined,
};

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

const applications: NavItemType = {
  id: 'group-applications',
  title: '',
  icon: icons.AppstoreAddOutlined,
  type: 'group',
  children: [
    // {
    //   id: "Dashboard",
    //   title: <FormattedMessage id="dashboard" />,
    //   type: "item",
    //   url: "/dashboard",
    //   icon: AreaChartOutlined,
    //   target: false,
    //   rights: "ngtRequest",
    // },
    {
      id: 'Import Project',
      title: <FormattedMessage id='import_project' />,
      type: 'item',
      url: '/create-backlog',
      icon: AddIcon,
      target: false,
      rights: 'ngtRequest',
    },
    {
      id: 'Create Task',
      title: <FormattedMessage id='create_task' />,
      type: 'item',
      url: '/create-task',
      icon: AddTaskIcon,
      target: false,
      rights: 'ngtRequest',
    },
    {
      id: 'List Backlogs',
      title: <FormattedMessage id='list_backlogs' />,
      type: 'item',
      url: '/list-backlogs',
      icon: AccountTreeIcon,
      target: false,
      rights: 'ngtTemplate',
    }
  ],
};

export default applications;
