import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";
// project import
import useAuth from 'hooks/useAuth';

// types
import { GuardProps } from 'types/auth';
import { encrypt } from "utils/commonFunctions";

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }: GuardProps) => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let cookieExist = Cookies.get(process.env.REACT_APP_EXPIRE_COOKIE || "");
    if (!cookieExist) {
      const host = window.location.host
      const parts = host.split(".");
      console.log(parts);
      parts.shift();
      console.log(parts);
      let domain = parts.join('.')
      console.log(domain);
      const externalUrl =
        `${
          process.env.REACT_APP_ACCOUNT_LOGIN
        }/auth/logout/${encodeURIComponent(encrypt(window.location.href))}` ||
        "/";
      window.location.assign(externalUrl);
    }
  }, [isLoggedIn, navigate, location]);

  // useEffect(() => {
  //   if (!isLoggedIn) {
  //     navigate('login', {
  //       state: {
  //         from: location.pathname
  //       },
  //       replace: true
  //     });
  //   }
  // }, [isLoggedIn, navigate, location]);

  return children;
};

export default AuthGuard;
